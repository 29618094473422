<template>
  <div>
    <b-modal id="confirmCloneModal" centered @hide="name = null">
      <validation-observer ref="cloneForm">
        <validation-provider
          #default="{ errors }"
          :name="$t('FormName')"
          rules="required"
        >
          <b-form-group :label="$t('FormName')">
            <b-input-group>
              <b-form-input v-model="name" :placeholder="$t('FormName')" />
            </b-input-group>
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
      <template #modal-footer="{ cancel }" style="justify-content: flex-start">
        <div>
          <b-button @click="cancel" class="mr-2" variant="outline-secondary">
            Cancelar
          </b-button>
          <b-button @click="handleSubmit" variant="primary"> 
            Aceptar 
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "ConfirmCloneFormModal",
  props: {
    form: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {
    ...mapActions({
      clone: 'forms/clone'
    }),
    async handleSubmit() {
      const valid = await this.$refs.cloneForm.validate()
      if (valid) {
       await this.clone({ id:this.form.id, name: this.name})

       this.name = ''
       this.$emit('cloned')
      }
    },
  },
};
</script>
